import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import { Icon } from "../../atoms/icon";
import { DeepBI, WebEngage } from "../../../integrations";
import { get } from "lodash";
import { btoa } from "isomorphic-base64";
import ResponsiveImageWithFallback from "../../atoms/responsive-image-with-fallback";

import { generateImageSources } from "../../utils/utils";

class AudioMagazinePlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      currentTime: 0,
      duration: 0,
      volume: 1,
      shouldRender: false,
      showSpeedOptions: false,
      selectedSpeed: 1,
      currentStoryId: get(this.props, ["story"])
    };

    this.audioRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.ClosePlayer) {
      if (prevProps.enablePlayer !== this.props.enablePlayer) {
        const magazineID = get(this.props, ["data", "issueCollection", "id"]);
        const storyId = get(this.props, ["CurrentStory", "id"]);
        const script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/npm/hls.js@latest";
        script.async = true;
        script.onload = () => {
          console.log("HLS.js script loaded");
          this.initializePlayer(magazineID, storyId);
        };
        document.body.appendChild(script);
      }
      if (prevProps.PlayAudio !== this.props.PlayAudio) {
        const magazine = get(this.props, ["data", "issueCollection"]);
        const story = get(this.props, ["CurrentStory"]);
        this.props.PlayAudio ? this.handlePlay(magazine, story) : this.handlePause(magazine, story);
      }
    }

    const currentStoryId = get(this.props, ["CurrentStory", "id"]);
    const prevStoryId = get(prevProps, ["CurrentStory", "id"]);

    if (prevStoryId !== undefined && currentStoryId !== prevStoryId) {
      // if (this.props.PlayAudio && this.props.enablePlayer) {
      const magazineID = get(this.props, ["data", "issueCollection", "id"]);
      this.initializePlayer(magazineID, currentStoryId);
      this.handlePlay(get(this.props, ["data", "issueCollection"]), get(this.props, ["CurrentStory"]));
      // }
    }
  }

  initializePlayer = (magazineID, storyId) => {
    const encodedMagazineID = btoa(magazineID);
    const audioUrl = `https://audiomag.vikatan.com/${encodedMagazineID}/${storyId}/${storyId}.m3u8`;
    if (window.Hls.isSupported()) {
      if (this.hls) {
        this.hls.destroy();
      }
      this.hls = new window.Hls();
      this.hls.loadSource(audioUrl);
      this.hls.attachMedia(this.audioRef.current);
      this.hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
        this.audioRef.current.playbackRate = this.state.selectedSpeed; // Set the playback speed
      });
    } else if (this.audioRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      this.audioRef.current.src = audioUrl;
      this.audioRef.current.load();
      this.audioRef.current.playbackRate = this.state.selectedSpeed; // Set the playback speed
    }
  };

  componentWillUnmount() {
    if (this.hls) {
      this.hls.destroy();
    }
  }

  handlePlay = (magazine, story) => {
    const audioElement = this.audioRef.current;

    if (audioElement) {
      const playPromise = audioElement.play();
      this.props.setAudioplaying(true);
      DeepBI.PingAudioplayer(
        story,
        audioElement.currentTime > 0 ? "audiomagazineresumed" : "audiomagazineplayed",
        magazine,
        this.formatSecondsTime(this.audioRef.current.currentTime)
      );
      this.setState({ playing: true, currentTime: audioElement.currentTime });
      let currentTime = audioElement.currentTime;

      WebEngage.Webplayer(story, currentTime > 0 ? "audiomagazineresumed" : "audiomagazineplayed", magazine);
      if (playPromise !== undefined) {
        // Handle the promise if it exists
        playPromise
          .then(() => {
            // Successfully started playback

            let currentTime = audioElement.currentTime;

            WebEngage.Webplayer(story, currentTime > 0 ? "audiomagazineresumed" : "audiomagazineplayed", magazine);
          })
          .catch(error => {
            // Handle playback error
            console.error("Playback error:", error);

            // Attempt to pause, reset, and retry playback
            audioElement.pause();
            audioElement.currentTime = 0;
            audioElement.play().catch(retryError => {
              console.error("Retry playback error:", retryError);
            });
          });
      } else {
        // Directly play if no promise is returned
        this.props.setAudioplaying(true);
        this.setState({ playing: true, currentTime: audioElement.currentTime });
        let currentTime = audioElement.currentTime;
        WebEngage.Webplayer(story, currentTime > 0 ? "audiomagazineresumed" : "audiomagazineplayed", magazine);
      }
    }
  };

  handlePause = (magazine, story) => {
    this.audioRef.current.pause();
    this.props.setAudioplaying(false);
    this.setState({ playing: false, currentTime: this.audioRef.current.currentTime });
    DeepBI.PingAudioplayer(
      story,
      "audiomagazinepaused",
      magazine,
      this.formatSecondsTime(this.audioRef.current.currentTime)
    );
    WebEngage.Webplayer(story, "audiomagazinepaused", magazine);
  };

  handleTimeUpdate = () => {
    this.setState({
      currentTime: this.audioRef.current.currentTime,
      duration: this.audioRef.current.duration
    });
  };

  setFinished = () => {
    this.setState({
      playing: false,
      currentTime: 0
    });
    this.props.setAudioplaying(this.props.PlayAudio);
    DeepBI.PingAudioplayer(
      get(this.props, ["CurrentStory"]),
      "audiomagazinecompleted",
      get(this.props, ["data", "issueCollection"]),
      this.formatSecondsTime(this.audioRef.current.currentTime)
    );
    WebEngage.Webplayer(
      get(this.props, ["CurrentStory"]),
      "audiomagazinecompleted",
      get(this.props, ["data", "issueCollection"])
    );
  };

  handleVolumeChange = e => {
    this.setState({ volume: e.target.value });
    this.audioRef.current.volume = e.target.value;
  };

  handleSeek = e => {
    this.audioRef.current.currentTime = e.target.value;
  };

  handleSpeedChange = speed => {
    const speeds = [1, 1.2, 1.5];
    const currentIndex = speeds.indexOf(speed);
    const newSpeed = currentIndex === speeds.length - 1 ? speeds[0] : speeds[currentIndex + 1];

    this.audioRef.current.playbackRate = newSpeed;
    this.setState({ selectedSpeed: newSpeed });

    // Logging events
    WebEngage.Webplayer(
      get(this.props, ["CurrentStory"]),
      "audiomagazinespeed",
      get(this.props, ["data", "issueCollection"])
    );
    DeepBI.PingAudioplayer(
      get(this.props, ["CurrentStory"]),
      "audiomagazinespeed",
      get(this.props, ["data", "issueCollection"]),
      this.formatSecondsTime(this.audioRef.current.currentTime)
    );
  };

  formatTime = time => {
    if (isNaN(time)) {
      return "00:00";
    }
    const remainder = time % 3600;
    const minutes = Math.floor(remainder / 60);
    const seconds = Math.floor(remainder % 60);

    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return `${mm}:${ss}`;
  };

  formatSecondsTime = time => {
    const remainder = time % 3600;
    const minutes = Math.floor(remainder / 60);
    const seconds = Math.floor(remainder % 60);

    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return parseInt(mm) * 60 + parseInt(ss);
  };

  skip = time => {
    if (time === "back") {
      this.audioRef.current.currentTime = this.audioRef.current.currentTime - 15;
      WebEngage.Webplayer(
        get(this.props, ["CurrentStory"]),
        "audiomagazineseekbackword",
        get(this.props, ["data", "issueCollection"])
      );
      DeepBI.PingAudioplayer(
        get(this.props, ["CurrentStory"]),
        "audiomagazineseekbackword",
        get(this.props, ["data", "issueCollection"]),
        this.formatSecondsTime(this.audioRef.current.currentTime)
      );
    } else if (time === "fwd") {
      this.audioRef.current.currentTime = this.audioRef.current.currentTime + 15;
      WebEngage.Webplayer(
        get(this.props, ["CurrentStory"]),
        "audiomagazineseekforward",
        get(this.props, ["data", "issueCollection"])
      );

      DeepBI.PingAudioplayer(
        get(this.props, ["CurrentStory"]),
        "audiomagazineseekforward",
        get(this.props, ["data", "issueCollection"]),
        this.formatSecondsTime(this.audioRef.current.currentTime)
      );
    }
  };

  handleNext = () => {
    if (this.hls) {
      this.hls.destroy();
    }
    const stories = get(this.props, ["data", "issueCollection", "items"] || []);
    const currentStoryId = get(this.props, ["CurrentStory", "id"]);
    // const magazineID = get(this.props, ["data", "issueCollection", "id"]);

    const currentStoryIndex = stories.findIndex(story => story.id === currentStoryId);

    if (currentStoryIndex === -1 || stories.length === 0) return; // No valid current story

    const storiesCount = stories.length;
    const nextIndex = (currentStoryIndex + 1) % storiesCount;
    const nextStory = stories[nextIndex];

    // Check if next story has audio available
    const audioAvailable = get(nextStory, ["story", "metadata", "story-attributes", "audiolength"], 0);
    if (audioAvailable > 0) {
      // console.log("audio-available");
      //   this.initializePlayer(magazineID, get(nextStory, ["story", "id"]));
      //  this.handlePlay(get(this.props, ["data", "issueCollection"]), get(nextStory, ["story"]));
      WebEngage.Webplayer(get(nextStory, ["story"]), "audiomagazinenext", get(this.props, ["data", "issueCollection"]));
      DeepBI.PingAudioplayer(
        get(nextStory, ["story"]),
        "audiomagazinenext",
        get(this.props, ["data", "issueCollection"])
      );
      this.props.setCurrentStory(get(nextStory, ["story"]));
      this.props.setAudioplaying(this.props.PlayAudio);
      this.setState({
        currentTime: 0
      });

      // this.setState({ currentStoryId: get(nextStory, ["story"]) });
    } else {
      console.log("audio- not available", currentStoryId);
    }
  };

  handlePrev = () => {
    if (this.hls) {
      this.hls.destroy();
    }
    const stories = get(this.props, ["data", "issueCollection", "items"], []);
    const currentStoryId = get(this.props, ["CurrentStory", "id"]);
    // const magazineID = get(this.props, ["data", "issueCollection", "id"]);

    if (stories.length === 0) return; // No stories available

    const currentStoryIndex = stories.findIndex(story => story.id === currentStoryId);
    if (currentStoryIndex === -1) return; // No valid current story

    const prevIndex = (currentStoryIndex - 1 + stories.length) % stories.length;
    const prevStory = stories[prevIndex];

    // Check if previous story has audio available
    const audioAvailable = get(prevStory, ["story", "metadata", "story-attributes", "audiolength"], 0);
    if (audioAvailable > 0) {
      //  console.log("Audio available for the previous story");
      //  this.initializePlayer(magazineID, get(prevStory, ["story", "id"]));
      // this.handlePlay(get(this.props, ["data", "issueCollection"]), get(prevStory, ["story"]));
      this.props.setCurrentStory(get(prevStory, ["story"]));
      this.props.setAudioplaying(this.props.PlayAudio);
      this.setState({
        currentTime: 0
      });

      WebEngage.Webplayer(
        get(prevStory, ["story"]),
        "audiomagazineprevious",
        get(this.props, ["data", "issueCollection"])
      );
      DeepBI.PingAudioplayer(
        get(prevStory, ["story"]),
        "audiomagazineprevious",
        get(this.props, ["data", "issueCollection"])
      );
    } else {
      console.log("Audio not available for the previous story", prevStory.id);
      // Optionally, you can call handlePrev recursively to skip stories without audio
      // this.handlePrev();
    }
  };

  closeMiniPlayer = () => {
    WebEngage.Webplayer(
      get(this.props, ["CurrentStory"]),
      "audiomagazineminiplayerclosed",
      get(this.props, ["data", "issueCollection"])
    );
    DeepBI.PingAudioplayer(
      get(this.props, ["CurrentStory"]),
      "audiomagazineminiplayerclosed",
      get(this.props, ["data", "issueCollection"]),
      this.formatSecondsTime(this.audioRef.current.currentTime)
    );
    if (this.audioRef.current) {
      this.audioRef.current.pause();
      this.audioRef.current.currentTime = 0;
      if (this.hls) {
        this.hls.destroy();
        this.hls = null;
      }
      this.audioRef.current.src = ""; // Reset the src to ensure proper reinitialization
      this.props.setMiniPlayerVisibility(false);
      this.props.setAudioplaying(false);
      this.props.setClosePlayer(true);
      this.props.setCurrentStory(get(this.props, ["story"]));
      this.setState({ selectedSpeed: 1, playing: false });
    }
  };

  render() {
    const { playing, currentTime, duration, selectedSpeed } = this.state;
    const { enablePlayer } = this.props;

    const storyHeadline = get(this.props, ["CurrentStory", "headline"], () => null);
    const storyImage = get(this.props, ["CurrentStory", "hero-image-s3-key"], () => null);
    const AudioStoryId = get(this.props, ["story", "id"]);

    if (!enablePlayer) {
      return <div className="no audio found" />;
    }

    return (
      <div className={styles["new-audio-player-wrapper"]}>
        <div className={styles["mini-player-toggle"]} onClick={this.closeMiniPlayer}>
          <Icon type="close" className={styles["mini-player-close-icon"]} />
        </div>
        <div className={styles["audio-player-wrapper"]}>
          <div>
            <div className={styles["mini-player-controls"]}>
              <div>
                <div className={`${styles["audio-controls"]} hidden-mobile`}>
                  <audio
                    id={`audio-${AudioStoryId}`}
                    ref={this.audioRef}
                    onTimeUpdate={this.handleTimeUpdate}
                    onEnded={this.setFinished}
                    preload="none"
                  />

                  <div className={styles["audio-header"]}>
                    {storyImage && (
                      <ResponsiveImageWithFallback
                        className={styles["image-wrapper"]}
                        slug={storyImage}
                        metadata={{}}
                        alt={storyHeadline}
                        imgParams={{ auto: ["format", "compress"] }}
                        sources={generateImageSources(
                          { aspectRatio: [4, 3], screenWidthCoverage: 0.34 },
                          { aspectRatio: [4, 3], screenWidthCoverage: 0.12 }
                        )}
                      />
                    )}
                  </div>
                  <div>
                    {storyHeadline && <h2 className={styles["audio-headline"]}>{storyHeadline}</h2>}

                    <div className={`${styles["audio-controls-grid"]}`}>
                      <div className={styles["audio-controls-icons"]}>
                        <div className={`${styles["audio-control-icons"]}`} onClick={this.handlePrev}>
                          <Icon type="icon-audio-previous" className={`${styles["audio-icon"]}`} />
                        </div>
                        <div
                          onClick={() => this.skip("back")}
                          className={`${styles["fast-backward-outer"]} ${styles["audio-control-icons"]}`}
                        >
                          <Icon type="backward" className={`${styles["fast-backward"]} ${styles["audio-icon"]}`} />
                        </div>
                        <div
                          onClick={() => this.skip("fwd")}
                          className={`${styles["fast-forward-outer"]} ${styles["audio-control-icons"]}`}
                        >
                          <Icon type="forward" className={`${styles["fast-forward"]} ${styles["audio-icon"]}`} />
                        </div>
                        <div className={`${styles["audio-control-icons"]}`} onClick={this.handleNext}>
                          <Icon type="icon-audio-next" className={`${styles["audio-icon"]}`} />
                        </div>
                        <div
                          className={`${styles["audio-control-icons"]}`}
                          onClick={playing ? this.handlePause : this.handlePlay}
                        >
                          {playing ? (
                            <Icon type="pause" className={`${styles["pause"]} ${styles["audio-icon"]}`} />
                          ) : (
                            <Icon type="play" className={`${styles["play"]} ${styles["audio-icon"]}`} />
                          )}
                        </div>
                      </div>
                      <div className={styles["audio-controls-top-section"]}>
                        <div className={styles["progress-bar"]}>
                          <input
                            type="range"
                            min={0}
                            max={duration}
                            step={0.01}
                            value={currentTime}
                            onChange={this.handleSeek}
                            className={styles["progress"]}
                          />
                        </div>
                        <div className={styles["audio-in-out-time"]}>
                          <div className={styles["audio-text"]}>{this.formatTime(currentTime)}</div>
                          <div className={styles["audio-text"]}>{this.formatTime(duration)}</div>
                        </div>
                      </div>
                      <div className={styles["audio-controls-bottom-section"]}>
                        <div className={styles["audio-controls-speed"]}>
                          <ul className={styles["speed-list"]}>
                            {[1, 1.2, 1.5].map(
                              speed =>
                                selectedSpeed === speed && (
                                  <li key={speed} onClick={() => this.handleSpeedChange(speed)}>
                                    {speed}x
                                  </li>
                                )
                            )}
                          </ul>
                          <div className="hidden-mobile">Speed</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`${styles["audio-controls"]} hidden-desktop`}>
                  <audio
                    id={`audio-${AudioStoryId}`}
                    ref={this.audioRef}
                    onTimeUpdate={this.handleTimeUpdate}
                    onEnded={this.setFinished}
                    preload="none"
                  />

                  <div className={styles["audio-header"]}>
                    {storyImage && (
                      // <figure className={styles["image-wrapper"]}>
                      //   <ResponsiveImage
                      //     eager
                      //     slug={storyImage} // Assuming storyImage is a slug for ResponsiveImage
                      //     metadata={{}} // Provide appropriate metadata if available
                      //     alt={storyHeadline}
                      //     aspectRatio={[16, 9]}
                      //     defaultWidth={320}
                      //     widths={[320, 500, 700]}
                      //     sizes="(max-width: 1024px) 100vw, 70vw"
                      //     imgParams={{ auto: ["format", "compress"] }}
                      //   />
                      // </figure>
                      <ResponsiveImageWithFallback
                        className={styles["image-wrapper"]}
                        slug={storyImage}
                        metadata={{}}
                        alt={storyHeadline}
                        imgParams={{ auto: ["format", "compress"] }}
                        sources={generateImageSources(
                          { aspectRatio: [4, 3], screenWidthCoverage: 0.34 },
                          { aspectRatio: [4, 3], screenWidthCoverage: 0.12 }
                        )}
                      />
                    )}
                  </div>
                  <div>
                    {storyHeadline && <h2 className={styles["audio-headline"]}>{storyHeadline}</h2>}
                    <div className={styles["audio-controls-top-section"]}>
                      <div className={styles["progress-bar"]}>
                        <input
                          type="range"
                          min={0}
                          max={duration}
                          step={0.01}
                          value={currentTime}
                          onChange={this.handleSeek}
                          className={styles["progress"]}
                        />
                      </div>
                      <div className={styles["audio-in-out-time"]}>
                        <div className={styles["audio-text"]}>{this.formatTime(currentTime)}</div>
                        <div className={styles["audio-text"]}>{this.formatTime(duration)}</div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles["audio-controls-grid"]}`}>
                    <div className={styles["audio-controls-icons"]}>
                      <div className={`${styles["audio-control-icons"]}`} onClick={this.handlePrev}>
                        <Icon type="icon-audio-previous" className={`${styles["audio-icon"]}`} />
                      </div>
                      <div
                        onClick={() => this.skip("back")}
                        className={`${styles["fast-backward-outer"]} ${styles["audio-control-icons"]}`}
                      >
                        <Icon type="backward" className={`${styles["fast-backward"]} ${styles["audio-icon"]}`} />
                      </div>
                      <div
                        className={`${styles["audio-control-icons"]}`}
                        onClick={playing ? this.handlePause : this.handlePlay}
                      >
                        {playing ? (
                          <Icon type="pause" className={`${styles["pause"]} ${styles["audio-icon"]}`} />
                        ) : (
                          <Icon type="play" className={`${styles["play"]} ${styles["audio-icon"]}`} />
                        )}
                      </div>
                      <div
                        onClick={() => this.skip("fwd")}
                        className={`${styles["fast-forward-outer"]} ${styles["audio-control-icons"]}`}
                      >
                        <Icon type="forward" className={`${styles["fast-forward"]} ${styles["audio-icon"]}`} />
                      </div>
                      <div className={`${styles["audio-control-icons"]}`} onClick={this.handleNext}>
                        <Icon type="icon-audio-next" className={`${styles["audio-icon"]}`} />
                      </div>
                      <div className={styles["audio-controls-speed"]}>
                        <ul className={styles["speed-list"]}>
                          <ul className={styles["speed-list"]}>
                            {[1, 1.2, 1.5].map(
                              speed =>
                                selectedSpeed === speed && (
                                  <li key={speed} onClick={() => this.handleSpeedChange(speed)}>
                                    {speed}x
                                  </li>
                                )
                            )}
                          </ul>
                        </ul>
                        <div className="hidden-mobile">Speed</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AudioMagazinePlayer.propTypes = {
  story: PropTypes.object.isRequired,
  storyImage: PropTypes.string,
  storyHeadline: PropTypes.string,
  id: PropTypes.string,
  playState: PropTypes.bool.isRequired,
  setMiniPlayerVisibility: PropTypes.bool.isRequired,
  enablePlayer: PropTypes.bool.isRequired,
  PlayAudio: PropTypes.bool.isRequired,
  setAudioplaying: PropTypes.bool.isRequired,
  setCurrentStory: PropTypes.object,
  ClosePlayer: PropTypes.bool.isRequired,
  setClosePlayer: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    data: get(state, ["qt", "data"]),
    enablePlayer: get(state, ["ShowPlayer"]),
    PlayAudio: get(state, ["PlayAudio"]),
    story: get(state, ["qt", "data", "story"], () => null),
    CurrentStory: get(state, ["CurrentStory"]),
    ClosePlayer: get(state, ["ClosePlayer"])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMiniPlayerVisibility: function(isVisible) {
      dispatch({
        type: "SET_MINI_PLAYER_VISIBILITY",
        payload: isVisible
      });
    },
    setAudioplaying: isVisible => {
      dispatch({
        type: "AUDIO_PLAYING",
        payload: isVisible
      });
    },
    setCurrentStory: story => {
      dispatch({
        type: "CURRENT_STORY",
        payload: story
      });
    },
    setClosePlayer: isVisible => {
      dispatch({
        type: "CLOSE_PLAYER",
        payload: isVisible
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioMagazinePlayer);
